import Xlsx from "./Xlsx.js";

let exportExcel = {};
exportExcel.base64 = (s) => {
    return window.btoa(unescape(encodeURIComponent(s)));
};
exportExcel.format = (s, c) => {
    return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
    });
};
exportExcel.tableToExcel = (table, name) => {
    debugger;
    // Define your style class template.
    var style = `<style>.el-table {
    background-color: #606266;
}</style>`;
    var uri = "data:application/vnd.ms-excel;base64,",
        template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>{table}</table></body></html>";

    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
    window.location.href = uri + exportExcel.base64(exportExcel.format(template, ctx));

};
exportExcel.tableCumtomer = async (data, config) => {
    let datasNeed = data.$refs.tableHeader.store.states._columns;
    let columns = data.columns;
    let datas = data.tableData
    let workbook = await Xlsx.IntFileXlsx();
    let header = Xlsx.style.header;
    let body = Xlsx.style.body;
    let levelMax = 0;
    let row = 1; // dòng khởi tạo
    let col = 65; // 65 là A mã IIC // cột khởi tạo
    let arrClom = [];

    if (config.row) row = config.row
    // lấy ra bậc cao nhất của header
    if (columns && columns.length > 0) {
        let colNow = 65;
        columns.forEach((column, index) => {

            let widthDefault = Math.round(Number(column.width) / 7);
            if (config && config.width) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.width).hidden(false); }
            else if (config && config.widthArr) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.widthArr[index] || 15).hidden(false); }
            else workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(widthDefault ? widthDefault : 15).hidden(false);
            colNow++
            if (column.level > levelMax) {
                levelMax = column.level
            }
            arrClom.push({
                align: column.align,
                property: column.property
            })

        })
    }

    // for lần 1
    if (datasNeed && datasNeed.length > 0) {

        datasNeed.forEach(dataNeed => {
            // A:C
            if (dataNeed.colSpan > 1 && levelMax > 0) {
                let col2 = String.fromCharCode(col + dataNeed.colSpan - 1); // cột khởi tạo + số cột meger
                workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeed.label || null}`).style(header);
                row++; // tăng dòng
            }
            //A1 :A5
            else if (dataNeed.rowSpan > 1 && levelMax > 0) {
                let row2 = row + dataNeed.rowSpan - 1; // dòng khởi tạo cộng số dòng cần meger
                workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeed.label || null}`).style(header);
                col++; // tăng cột
            }
            // A1:C5
            else if (dataNeed.colSpan > 1 && dataNeed.rowSpan && levelMax > 0) {
                workbook.sheet("Sheet1").range(` ${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeed.colSpan)}${row + dataNeed.rowSpan}`).merged(true).value(`${dataNeed.label || null}`).style(header);

                row++;
                col++;
                // chưa check
            }
            else {
                // không có meger Cell
                workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeed.label || null}`).style(header);

                if (levelMax > 1)
                    row++; // tăng dòng
                else
                    col++;
            }


            if (dataNeed.children && dataNeed.children.length > 0) {

                dataNeed.children.forEach(dataNeedChi => {
                    if (dataNeedChi.colSpan > 1 && levelMax > 0) {
                        let col2 = String.fromCharCode(col + dataNeedChi.colSpan - 1);
                        workbook.sheet("Sheet1").range(` ${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                        // row ++;
                    }
                    else if (dataNeedChi.rowSpan > 1 && levelMax > 0) {
                        let row2 = row + dataNeedChi.rowSpan - 1;
                        workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                        col++;
                    }
                    else if (dataNeedChi.colSpan > 1 && dataNeedChi.rowSpan && levelMax > 0) {
                        workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeedChi.colSpan)}${row + dataNeedChi.rowSpan}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                        row++;
                        col++;
                    }
                    else {
                        workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeedChi.label || null}`).style(header);
                        if (levelMax == 3)
                            row++;
                        if (levelMax == 2)
                            col++;
                    }


                    if (dataNeedChi.children && dataNeedChi.children.length > 0) {
                        dataNeedChi.children.forEach(dataNeedChi2 => {
                            if (dataNeedChi2.colSpan > 1 && levelMax > 0) {
                                let col2 = String.fromCharCode(col + dataNeedChi2.colSpan - 1);
                                workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                            }
                            else if (dataNeedChi2.rowSpan > 1 && levelMax > 0) {
                                let row2 = row + dataNeedChi2.rowSpan - 1;
                                workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                                col++;
                            }
                            else if (dataNeedChi2.colSpan > 1 && dataNeedChi2.rowSpan && levelMax > 0) {
                                workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeedChi2.colSpan)}${row + dataNeedChi2.rowSpan}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                                row++;
                                col++;
                            }
                            else {
                                workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeedChi2.label || null}`).style(header);

                                col++;
                            }
                        })
                        row--;
                    }
                })
                row--;
            }
        })
    }
    // For dữ liệu
    if (datas && datas.length > 0) {
        let rowNow = levelMax + row;
        datas.forEach(data => {
            let colNow = 65;
            let index = 0;
            let align = "";

            for (const elementData in data) {
                //check vị trí
                let value = (data[elementData] || '').toString();
                if (arrClom[index]) {
                    if (arrClom[index].align == "is-right") { align = "right"; }
                    else if (arrClom[index].align === "is-left") align = "left";
                    else if (arrClom[index].align === "is-center") align = "center";
                    else align = "left";
                } else {
                    align = "left";
                }


                workbook.sheet("Sheet1").cell(`${String.fromCharCode(colNow)}${rowNow}`).value(`${value}`).style(body).style({ horizontalAlignment: align });
                colNow++;
                index++;
            }
            rowNow++;

        })
    }


    return Xlsx.SaveFile(workbook, config);
}


exportExcel.tableCumtomerOneTH = async (data, config) => {
    let datasNeed = data.$refs.tableHeader.store.states._columns;
    let columns = data.columns;
    let datas = data.tableData
    let workbook = await Xlsx.IntFileXlsx();
    let header = Xlsx.style.header;
    let body = Xlsx.style.body;
    let row = 1; // dòng khởi tạo
    let col = 65; // 65 là A mã IIC // cột khởi tạo
    let arrClom = [];

    if (config.row) row = config.row
    // lấy ra bậc cao nhất của header
    if (columns && columns.length > 0) {
        let colNow = 65;
        columns.forEach((column, index) => {

            let widthDefault = Math.round(Number(column.width) / 7);
            if (config && config.width) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.width).hidden(false); }
            else if (config && config.widthArr) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.widthArr[index] || 15).hidden(false); }
            else workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(widthDefault ? widthDefault : 15).hidden(false);
            colNow++
            arrClom.push({
                align: column.align,
                property: column.property
            })

        })
    }
    // for lần 1
    if (datasNeed && datasNeed.length > 0) {

        datasNeed.forEach(dataNeed => {
            if (dataNeed.property) {
                // không có meger Cell
                workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeed.label || null}`).style(header);
                col++; // tăng dòng
            }


        })
    }
    // For dữ liệu
    if (datas && datas.length > 0) {
        let rowNow = 2;
        datas.forEach(data => {
            let colNow = 65;
            let index = 0;
            let align = "";

            for (const elementData in data) {
                if (elementData != "id") {
                    //check vị trí
                    let value = (data[elementData] || '').toString();
                    if (arrClom[index].align == "is-right") { align = "right"; }
                    else if (arrClom[index].align === "is-left") align = "left";
                    else if (arrClom[index].align === "is-center") align = "center";
                    else align = "left";

                    workbook.sheet("Sheet1").cell(`${String.fromCharCode(colNow)}${rowNow}`).value(`${value}`).style(body).style({ horizontalAlignment: align });
                    colNow++;
                    index++;
                }

            }
            rowNow++;

        })
    }


    return Xlsx.SaveFile(workbook, config);
}
exportExcel.exportFullExcel = async (data, tableData, config) => {
  let datasNeed = data.$refs.tableHeader.store.states._columns.filter(x => x.property);
  let columns = data.columns.filter(x => x.property);
  let workbook = await Xlsx.IntFileXlsx();
  let header = Xlsx.style.header;
  let body = Xlsx.style.body;
  let levelMax = 0;
  let row = 1; // dòng khởi tạo
  let col = 65; // 65 là A mã IIC // cột khởi tạo
  let arrClom = [];

  if (config.row) row = config.row

  // lấy ra bậc cao nhất của header
  if (columns && columns.length > 0) {
      let colNow = 65;
      columns.forEach((column, index) => {

          let widthDefault = Math.round(Number(column.width) / 7);
          if (config && config.width) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.width).hidden(false); }
          else if (config && config.widthArr) { workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(config.widthArr[index] || 15).hidden(false); }
          else workbook.sheet("Sheet1").column(`${String.fromCharCode(colNow)}`).width(widthDefault ? widthDefault : 15).hidden(false);
          colNow++
          if (column.level > levelMax) levelMax = column.level
          arrClom.push({
              align: column.align,
              property: column.property
          })

      })
  }

  // for lần 1
  if (datasNeed && datasNeed.length > 0) {

      datasNeed.forEach(dataNeed => {
          // A:C
          if (dataNeed.colSpan > 1 && levelMax > 0) {
              let col2 = String.fromCharCode(col + dataNeed.colSpan - 1); // cột khởi tạo + số cột meger
              workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeed.label || null}`).style(header);
              row++; // tăng dòng
          }
          //A1 :A5
          else if (dataNeed.rowSpan > 1 && levelMax > 0) {
              let row2 = row + dataNeed.rowSpan - 1; // dòng khởi tạo cộng số dòng cần meger
              workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeed.label || null}`).style(header);
              col++; // tăng cột
          }
          // A1:C5
          else if (dataNeed.colSpan > 1 && dataNeed.rowSpan && levelMax > 0) {
              workbook.sheet("Sheet1").range(` ${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeed.colSpan)}${row + dataNeed.rowSpan}`).merged(true).value(`${dataNeed.label || null}`).style(header);

              row++;
              col++;
              // chưa check
          }
          else {
              // không có meger Cell
              workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeed.label || null}`).style(header);

              if (levelMax > 1)
                  row++; // tăng dòng
              else
                  col++;
          }


          if (dataNeed.children && dataNeed.children.length > 0) {

              dataNeed.children.forEach(dataNeedChi => {
                  if (dataNeedChi.colSpan > 1 && levelMax > 0) {
                      let col2 = String.fromCharCode(col + dataNeedChi.colSpan - 1);
                      workbook.sheet("Sheet1").range(` ${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                      // row ++;
                  }
                  else if (dataNeedChi.rowSpan > 1 && levelMax > 0) {
                      let row2 = row + dataNeedChi.rowSpan - 1;
                      workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                      col++;
                  }
                  else if (dataNeedChi.colSpan > 1 && dataNeedChi.rowSpan && levelMax > 0) {
                      workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeedChi.colSpan)}${row + dataNeedChi.rowSpan}`).merged(true).value(`${dataNeedChi.label || null}`).style(header);

                      row++;
                      col++;
                  }
                  else {
                      workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeedChi.label || null}`).style(header);
                      if (levelMax == 3)
                          row++;
                      if (levelMax == 2)
                          col++;
                  }


                  if (dataNeedChi.children && dataNeedChi.children.length > 0) {
                      dataNeedChi.children.forEach(dataNeedChi2 => {
                          if (dataNeedChi2.colSpan > 1 && levelMax > 0) {
                              let col2 = String.fromCharCode(col + dataNeedChi2.colSpan - 1);
                              workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${col2}${row}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                          }
                          else if (dataNeedChi2.rowSpan > 1 && levelMax > 0) {
                              let row2 = row + dataNeedChi2.rowSpan - 1;
                              workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col)}${row2}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                              col++;
                          }
                          else if (dataNeedChi2.colSpan > 1 && dataNeedChi2.rowSpan && levelMax > 0) {
                              workbook.sheet("Sheet1").range(`${String.fromCharCode(col)}${row}:${String.fromCharCode(col + dataNeedChi2.colSpan)}${row + dataNeedChi2.rowSpan}`).merged(true).value(`${dataNeedChi2.label || null}`).style(header);

                              row++;
                              col++;
                          }
                          else {
                              workbook.sheet("Sheet1").cell(`${String.fromCharCode(col)}${row}`).value(`${dataNeedChi2.label || null}`).style(header);

                              col++;
                          }
                      })
                      row--;
                  }
              })
              row--;
          }
      })
  }

  // For dữ liệu
  if (tableData && tableData.length > 0) {
    let rowNow = levelMax + row;
    tableData.forEach(data => {
        let colNow = 65;
        let index = 0;
        let align = "";

        for (const elementData in data) {
            //check vị trí
            let value = (data[elementData] || '').toString();
            if (arrClom[index] && arrClom[index].property === elementData) {
                if (arrClom[index].align == "is-right") { align = "right"; }
                else if (arrClom[index].align === "is-left") align = "left";
                else if (arrClom[index].align === "is-center") align = "center";
                else align = "left";
                workbook.sheet("Sheet1").cell(`${String.fromCharCode(colNow)}${rowNow}`).value(`${value}`).style(body).style({ horizontalAlignment: align });
            } else {
                align = "left";
            }

            colNow++;
            index++;
        }
        rowNow++;

    })
  }


  return Xlsx.SaveFile(workbook, config);

  return Xlsx.SaveFile(workbook, config);
}

export default exportExcel;
