<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{aLang.c_list}} kết quả biểu mẫu
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <div class="text-left">
          <b-collapse v-bind:visible="mData.tableRules.showFormSearch" id="myFormSearch">
            <b-card>
              <el-form
                ref="form"
                v-model="mData.surveyItems"
                label-width="120px"
                label-position="top"
              >
                <b-row>
                  <b-col md="3" v-for="surveyItem in mData.surveyItems" :key="surveyItem.code">
                    <el-form-item :label="surveyItem.title">
                      <el-input
                        v-if="surveyItem.inputControl=='textbox' || surveyItem.inputControl=='number' || surveyItem.inputControl=='email'"
                        v-model="mData.model[[surveyItem.code]+'textbox']"
                        placeholder="Nhập giá trị"
                      ></el-input>
                      <el-select
                        v-if="surveyItem.inputControl!='textbox' && surveyItem.inputControl!='image' && surveyItem.inputControl!='label'"
                        v-model="mData.model[surveyItem.code]"
                        placeholder="Chọn giá trị"
                        clearable
                        filterable
                        multiple
                      >
                        <template
                          v-for="(dataSurveyResult,index) in lodash.uniqWith(mData.dataSearchResult,(arrVal, othVal) => arrVal[surveyItem.code] === othVal[surveyItem.code])"
                        >
                          <el-option
                            v-if="dataSurveyResult[surveyItem.code]"
                            v-bind:key="index"
                            :label="dataSurveyResult[surveyItem.code]"
                            :value="dataSurveyResult[surveyItem.code]"
                          ></el-option>
                        </template>
                      </el-select>
                    </el-form-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col>
                    <el-form-item>
                      <el-button type="primary" @click="intTable">Tìm kiếm</el-button>
                    </el-form-item>
                  </b-col>
                </b-row>
              </el-form>
            </b-card>
          </b-collapse>
        </div>
        <b-row class="d-flex justify-content-end mr-0">
            <div class="text-right mb-2 ml-2">
              <el-button @click="openSelectColumns" type="info" plain>Chọn cột hiển thị</el-button>
            </div>
            <div class="text-right mb-2 ml-2">
              <el-button
                @click="fn_tableShowFormSearch()"
                type="info"
                plain
              >{{aLang.c_showHideSearch}}</el-button>
            </div>
            <div class="text-right mb-2 ml-2">
              <el-button @click="downloadFileExcel" type="info" plain>Xuất excel</el-button>
            </div>
            <div class="text-right mb-2 ml-2">
              <el-button @click="callBack()" type="info" plain>Quay lại</el-button>
            </div>
            <div class="text-right mb-2 ml-2" v-if="createAble">
              <el-button @click="fn_handle('create')" type="info" plain>Thêm mới</el-button>
            </div>
        </b-row>

        <el-table
          :data="mData.surveyResults"
          border
          @selection-change="fn_tableSelectionChange"
          @sort-change="fn_tableSortChange"
          max-height="440"
          style="width: 100%"
          ref="surveyDetail"
          :default-sort="{prop: mData.tableRules.defaultSort[0], order: mData.tableRules.defaultSort[1]}"
        >
          <el-table-column
            v-for="surveyItem in mData.surveyItems"
            :key="surveyItem.code"
            :prop="surveyItem.code"
            :label="surveyItem.title"
            min-width="100px"
            :class-name="surveyItem.code"
          >
            <span slot-scope="scope">
              {{ scope.row[surveyItem.code] }}
            </span>
            <!-- <span slot-scope="scope" v-if="['selectbox', 'checkbox', 'radio'].includes(surveyItem.inputControl)">
              <template v-if="surveyItem.dataSource.type !== 'api'">
                {{ mData.listDataSource[surveyItem.code][scope.row[surveyItem.code]] }}
              </template>
              <template v-if="surveyItem.dataSource.type === 'api'">
                {{ mData.listDataSource[surveyItem.code].find(x => x[surveyItem.dataSource.requestApi.mappingFields.key] === scope.row[surveyItem.dataSource.requestApi.mappingFields.key])[surveyItem.dataSource.requestApi.mappingFields.value] || "" }}
              </template>
            </span>
            <span slot-scope="scope" v-else>
              {{ scope.row[surveyItem.code] }}
            </span> -->
          </el-table-column>
          <el-table-column :label="aLang.c_action" align="center" width="200" v-if="isReach">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="fn_handle('detail', scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_detail"
              >
                <i class="fa fa-eye"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('update', scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_update"
              >
                <i class="icon-pencil icons"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('delete',scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_delete"
              >
                <i class="icon-trash icons"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('confirm', scope.$index, scope.row)"
                v-b-popover.hover.left="'Xác nhận'"
                v-if="!scope.row.is_confirmed"
              >
                <i class="icon-check icons"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <b-row class="mt-2" v-if="mData.tableRules.allowPaging">
          <div class="col-md-4">
            <div
              v-if="mData.tableRules.allowSelect"
            >{{aLang.c_select}}: {{mData.tableRules.recordSelected.length}}</div>
          </div>
          <div class="col-md-8 text-right">
            <el-pagination
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              :page-sizes="mData.tableRules.lengthMenu"
              :page-size="mData.tableRules.limit"
              :current-page="mData.tableRules.page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="mData.tableRules.total"
              :pager-count="5"
            ></el-pagination>
          </div>
        </b-row>
      </div>
    </div>
    <el-dialog title="Columns" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div style="margin-top: 15px!important;width: 80%;margin: auto;" class="row mt-2">
        <el-checkbox
          class="col-md-12"
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          border
          size="small"
        >Check all</el-checkbox>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" style="width: 100%;">
          <el-checkbox
            class="col-md-12"
            v-for="item in mData.surveyItemsOriginalData"
            :label="item.code"
            :value="item.code"
            :key="item.id"
            border
            size="medium"
          >{{item.title}} {{item.code}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="displayColumns">Ok</el-button>
        <el-button @click="dialogVisible = false">Hủy bỏ</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
import LanguageService from "../../../service/LanguageService";
import MethodService from "../../../service/MethodService";
import AccountModel from "./SurveyModel";
import SurveyItemApi from "../../../moduleApi/SurveyItemApi";
import SurveyApi from "../../../moduleApi/SurveyApi";
import SurveyResultApi from "../../../moduleApi/SurveyResultApi";
import PrefillApi from "../../../moduleApi/PrefillApi";
import ApplicationApi from "../../../moduleApi/ApplicationApi";
import ApiServiceFetch from '../../../service/ApiServiceFetch';
import ExportExcel from "../../../component/ExportExcel.js";
import mushroom from "../../../service/mushroom.api.def";

import _ from "lodash";
export default {
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      isReach: JSON.parse(localStorage.currentUser).account === 'reach',
      createAble: false,
      defaultRequestData: {},
      applicationEnvironment: null,

      lodash: _,
      dialogVisible: false,
      aRole: MethodService.checkRole,
      aLang: LanguageService.lang,
      aElement: MethodService.element,
      mData: {
        configXlsx: { nameFile: "surveyDetail" },
        filters: "",
        model: {},
        surveyResults: [],
        surveyResultsData: [],
        surveyItems: [],
        surveyItemsOriginalData: [],
        dataSearchResult: [],
        moduleName: "Survey", //requied
        tableRules: MethodService.copyObject(AccountModel.tableRules),
        listDataSource: {},
        dataDefault: {
          listFindNongTrai: [],
        }
      }
    };
  },

  methods: {
    // XLSX
    async downloadFileExcel() {
      //  ExportExcel.tableToExcel("tableDotNhienLieu", this.mData.configXlsx );
      let config = this.mData.configXlsx;
      config.width = 25;

      let resSR = await this.GetSurveyResult({
        page: 1,
        offset: 0,
        limit: this.mData.tableRules.total,
        filters: this.mData.filters.concat([`surveyId=${this.$router.currentRoute.params.id}`]),
        fields: 'id,surveyId,result,createdTime,lastModifiedTime,callback.status,requestData,surveyToken'
      });
      let dataSurveyResults = await this.filterSurveyResult(
        this.mData.surveyItems,
        resSR.result
      );
      const tableData = await this.changeData(dataSurveyResults);
      ExportExcel.exportFullExcel(this.$refs.surveyDetail, tableData, config);
    },
    //take data survey result
    async GetSurveyResult(dataFilter) {
      let res = [];
      try {
        res = await SurveyResultApi.list(dataFilter);
        return res;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    //take data survey item
    async GetSurveyItem(surveyId, code) {
      if (surveyId) {
        try {
          let params = {
            filters: `surveyId=${surveyId}`
          };

          let res = await SurveyItemApi.list(params);
          res.result.filter(x => ['selectbox', 'checkbox', 'radio'].includes(x.inputControl) && x.dataSource).forEach(item => {
            if (item.dataSource.type !== 'api') {
              this.mData.listDataSource[item.code] = JSON.parse(item.dataSource.value);
            }
          })
          return res.result.filter(x => x.inputControl !== 'hidden');
        } catch (error) {
          console.log(error);
          return [];
        }
      } else {
        return [];
      }
    },
    // convert data survey result so sanh data survey item
    async filterSurveyResult(surveyItems, surveyResults) {
      let dataResults = [];
      if (
        surveyItems &&
        surveyItems.length > 0 &&
        surveyResults &&
        surveyResults.length > 0
      ) {
        let newSurveyResults1 = [];
        // tách surveyResults lấy result gép vào mảng
        surveyResults.forEach(surveyResult => {
          newSurveyResults1.push(surveyResult.result);
        });
        //tách newSurveyResults1 trả về newSurveyResults2 và mỗi newSurveyResults2 là một mảng
        newSurveyResults1.filter(x => x).forEach((newSurveyResults2, index) => {
          const filteredArray = newSurveyResults2.filter(x =>
            surveyItems.find(y => y.code === x.code && (y.id === x.surveyItemId || y.code === x.code))
          );
          const reduceResult = filteredArray.reduce(function(
            accumulator,
            currentValue
          ) {
            const newObject = {};
            newObject[currentValue.code] = currentValue.value;
            return Object.assign(accumulator, newObject);
          },
          {});
          reduceResult.id = surveyResults[index].id;
          dataResults.push(reduceResult);
        });
        return dataResults;
      }
    },
    IsRegex(operator, value) {
      const regex = new RegExp(operator);

      return regex.test(value);
    },

    //get list table
    async intTable() {
      let surveyItems = await this.GetSurveyItem(
        this.$router.currentRoute.params.id
      );
      const arrNewsItems = surveyItems.filter(surveyItem => {
        if (
          !(
            surveyItem.inputControl == "label" ||
            surveyItem.inputControl == "image"
          )
        )
          return surveyItem;
      });

      let filters = [];
      try {
        const objectArray = Object.entries(this.mData.model);

        objectArray.forEach(([key, value]) => {
          if (this.IsRegex(/\w+textbox$/gm, key) && value) {
            key = key.replace(/textbox$/gi, "");
            filters.push(`result:elemMatch:code=${key},value=${value}`);
          }
          if (value && Array.isArray(value) && value.length > 0) {
            filters.push(`result:elemMatch:code=${key},value:in:${value}`);
          }
        });
      } catch (e) {
        console.log(e);
      }
      this.mData.filters = filters;

      let dataFilter = {
        page: this.mData.tableRules.page,
        offset: this.mData.tableRules.offset,
        limit: this.mData.tableRules.limit,
        filters: filters.concat([`surveyId=${this.$router.currentRoute.params.id}`]),
        fields: 'id,surveyId,result,createdTime,lastModifiedTime,callback.status,requestData,surveyToken'
      };
      if (this.mData.tableRules.showUrl) {
        this.$router
          .replace({
            name: "Admin-" + this.mData.moduleName + "-detail",
            query: {
              limit: this.mData.tableRules.limit,
              page: this.mData.tableRules.page,
              offset: this.mData.tableRules.offset,
              filters: filters,
              showFormSearch: this.mData.tableRules.showFormSearch
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      // ["result.code=col_hoten", "result.value:like:%Hà%"]
      let resSR = await this.GetSurveyResult(dataFilter);
      let surveyResults = resSR.result;
      this.mData.surveyResultsData = surveyResults;

      let dataSurveyResults = await this.filterSurveyResult(
        surveyItems,
        surveyResults
      );
      let { result: searchsurveyResults } = await this.GetSurveyResult({
        limit: 10000
      });
      let dataSearchResult = await this.filterSurveyResult(
        surveyItems,
        searchsurveyResults
      );
      //dataSearch
      this.mData.dataSearchResult = dataSearchResult;
      //dataItem(key)
      this.mData.surveyItems = arrNewsItems;
      // if (this.isReach) {
      //   this.mData.surveyItems = this.mData.surveyItems.filter(x => [ 'trung_tam', 'khoa', 'lop', 'ho_ten', 'sdt_ca_nhan', 'hovaten', 'lophoc1_trungtam', 'lophoc1_khoa', 'lophoc1_lop', 'lophoc2_trungtam', 'lophoc2_khoa', 'lophoc2_lop' ].includes(x.code));
      // }
      this.mData.surveyItemsOriginalData = JSON.parse(JSON.stringify(this.mData.surveyItems));
      //dataTable
      this.mData.surveyResults = await this.changeData(dataSurveyResults);

      try {
        this.mData.tableRules.total = resSR.meta.total;
        this.mData.tableRules.page = resSR.meta.offset / resSR.meta.limit + 1;
        if (
          this.mData.tableRules.offset == resSR.meta.total &&
          resSR.meta.total != 0
        ) {
          this.mData.tableRules.offset = 0;
          this.mData.tableRules.page = 1;
          this.intTable();
        }
      } catch (e) {
        console.log(e);
      }
    },

    changeData(data) {
      return new Promise((resolve) => {
        const listItemApi = this.mData.surveyItems.filter(x => ['selectbox', 'checkbox', 'radio'].includes(x.inputControl) && x.dataSource && x.dataSource.type === 'api');
        const lastIdx = listItemApi.length - 1;
        listItemApi.forEach(async (item, index) => {
          const dataSource = item.dataSource.requestApi;
          const apiUrls = [...new Set(data.map(row => this.generateUrl(dataSource.url, dataSource.surveyItemCode, row)))];
          // Promise.all(apiUrls.map(url => this.resolveApi(dataSource, url))).then(resData => {
          //   this.mData.listDataSource[item.code] = resData.flat();

          //   if (index === lastIdx) resolve(this.mappingFieldData(data));
          // })

          const resData = await this.processPromisesBatch(apiUrls, 100, url => this.resolveApi(dataSource, url));
          this.mData.listDataSource[item.code] = resData.flat();

          if (index === lastIdx) resolve(this.mappingFieldData(data));
        })
      });
    },
    mappingFieldData(data) {
      $.each(data, (index, row) => {
        this.mData.surveyItems.filter(x => ['selectbox', 'checkbox', 'radio'].includes(x.inputControl) && x.dataSource).forEach(async item => {
          if (item.dataSource.type === 'api') {
            let dataFind = this.mData.listDataSource[item.code].find(x => x[item.dataSource.requestApi.mappingFields.key] == row[item.code]);
            if (dataFind) row[item.code] = dataFind[item.dataSource.requestApi.mappingFields.value];
            else row[item.code] = this.mData.listDataSource[item.code][row[item.code]];
          } else {
            row[item.code] = this.mData.listDataSource[item.code][row[item.code]];
          }
        })
      });
      return data;
    },
    async processPromisesBatch(items, limit, fn) {
      let results = [];
      for (let start = 0; start < items.length; start += limit) {
        const end = start + limit > items.length ? items.length : start + limit;

        const slicedResults = await Promise.all(items.slice(start, end).map(fn));

        results = [
          ...results,
          ...slicedResults,
        ]
      }

      return results;
    },
    async resolveApi(dataSource, url) {
      let response = await ApiServiceFetch({
        method: dataSource.method,
        url: url,
        headers: dataSource.headers,
        body: dataSource.body,
        message: {
          success: "",
          error: "",
        },
      });
      return response.result;
    },
    generateUrl(url, paramsCode, data) {
      paramsCode.forEach(itemCode => {
        url = url.replace("{" + itemCode + "}", data[itemCode] || "");
        url = url.replace("{viewMode}", 'view');
      });
      return url;
    },

    displayColumns() {
      let checked = this.checkedCities;
      let surveyItem = JSON.parse(JSON.stringify(this.mData.surveyItemsOriginalData));
      if (checked && checked.length < 1) {
        this.$alert("Bạn chưa chọn trường nào hiển thị", {
          confirmButtonText: "OK"
        });
      } else {
        if (surveyItem && surveyItem.length > 0) {
          surveyItem = surveyItem.filter(x => {
            return (x.code = checked.find(y => y === x.code));
          });
          this.mData.surveyItems = surveyItem;
          // this.intTable();
        }
        this.dialogVisible = false;
      }
    },
    openSelectColumns() {
      this.dialogVisible = true;
      this.checkedCities = [];
    },
    handleClose(done) {
      done();
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    fn_tableSelectionChange() {},
    fn_tableSortChange(column) {
      if (column.order == "ascending") {
        this.mData.tableRules.sort = column.prop;
      } else if (column.order == "descending") {
        this.mData.tableRules.sort = "-" + column.prop;
      } else {
        this.mData.tableRules.sort = "";
      }
      this.intTable();
    },
    callBack() {
      this.$router.push({ name: "Admin-Survey-list" });
    },

    //event table
    fn_tableSizeChange(limit) {
      this.mData.tableRules.limit = limit;
      this.fn_tableChangeOffset(this.mData.tableRules.page);
    },
    fn_tableCurentChange(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tablePrevClick(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tableNextClick(page) {
      this.fn_tableChangeOffset(page);
    },

    fn_tableChangeOffset(page) {
      this.mData.tableRules.page = page;
      this.mData.tableRules.offset =
        (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      this.intTable();
    },

    fn_tableShowFormSearch() {
      this.mData.tableRules.showFormSearch = !this.mData.tableRules
        .showFormSearch;
      let query = Object.assign({}, this.$route.query);
      query.showFormSearch = this.mData.tableRules.showFormSearch;
      this.$router.push({
        name: "Admin-" + this.mData.moduleName + "-detail",
        query: query
      });
    },

    async fn_hashUrl() {
      if (this.$route.query.limit) {
        this.mData.tableRules.limit = parseInt(this.$route.query.limit);
      }
      if (this.$route.query.page) {
        this.mData.tableRules.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.limit && this.$route.query.page) {
        this.mData.tableRules.offset =
          (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      }
      if (this.$route.query.sort) {
        this.mData.tableRules.sort = this.$route.query.sort;
        this.mData.tableRules.defaultSort[0] = this.mData.tableRules.sort;
        if (this.mData.tableRules.sort.indexOf("-") != -1) {
          this.mData.tableRules.defaultSort[1] = "descending";
        } else {
          this.mData.tableRules.defaultSort[1] = "ascending";
        }
      }
      if (this.$route.query.showFormSearch == "true") {
        this.mData.tableRules.showFormSearch = true;
      }
      if (this.$route.query.showFormSearch == "false") {
        this.mData.tableRules.showFormSearch = false;
      }
      // if (this.$route.query.filters) {

      // }
    },

    async fn_handle(type, scope, row) {
      let surveyResult = null;
      switch (type) {
        case "create":
          this.$router.push({
            name: "Admin-" + this.mData.moduleName + `-${type}Survey`,
            params: {
              id: this.$router.currentRoute.params.id
            },
            query: {
              privateKey: this.defaultRequestData.privateKey,
              env: this.defaultRequestData.env,
              appCode: this.defaultRequestData.appCode,
              hashCode: this.defaultRequestData.hashCode,
              prefillId: "",
              callbackUrl: this.$router.resolve({ name: "Admin-Survey-detail", params: { id: this.$router.currentRoute.params.id } }).href,
              viewMode: type,
            }
          });
          break;
        case "update":
        case "detail":
          surveyResult = this.mData.surveyResultsData.find(x => x.id == row.id);
          let prefillSurvey = {};
          if (row) {
            row.surveyToken = surveyResult.surveyToken;
            let objJsonB64 = Buffer.from(
              JSON.stringify(row)
            ).toString("base64");
            prefillSurvey = await PrefillApi.create({ data: objJsonB64 });
          }

          this.$router.push({
            name: "Admin-" + this.mData.moduleName + `-${type}Survey`,
            params: {
              id: this.$router.currentRoute.params.id
            },
            query: {
              privateKey: this.applicationEnvironment.find(x => x.envType == surveyResult.requestData.env).privateKey,
              env: surveyResult.requestData.env,
              appCode: surveyResult.requestData.appCode,
              prefillId: prefillSurvey.result,
              callbackUrl: this.$router.resolve({ name: "Admin-Survey-detail", params: { id: this.$router.currentRoute.params.id } }).href,
              viewMode: type,
              id: row.id
            }
          });

          break;
        case "delete":
          this.$confirm(LanguageService.lang.c_deleteConfirm, {
            confirmButtonText: LanguageService.lang.c_agree,
            cancelButtonText: LanguageService.lang.c_cancel,
            type: "warning"
          }).then(async () => {
            await SurveyResultApi.delete(row.id);
            this.intTable();
          })
          .catch(() => {});
          break;
        case "confirm":
          this.$confirm("Xác nhận thông tin hồ sơ này", {
            confirmButtonText: LanguageService.lang.c_agree,
            cancelButtonText: LanguageService.lang.c_cancel,
            type: "warning"
          }).then(async () => {
            surveyResult = this.mData.surveyResultsData.find(x => x.id == row.id);
            let body = {
              id: row.id,
              surveyId: this.$router.currentRoute.params.id,
              surveyToken: surveyResult.surveyToken,
              result: row
            }
            let params = {
              env: surveyResult.requestData.env,
              appCode: surveyResult.requestData.appCode,
              hashCode: surveyResult.requestData.hashCode,
              callbackUrl: this.$router.resolve({ name: "Admin-Survey-detail", params: { id: this.$router.currentRoute.params.id } }).href,
              surveyToken: surveyResult.surveyToken,
              id: row.id
            }
            let res = await SurveyResultApi.confirm(body, params);
            if (res.result) {
              toastr.success("Xác nhận thông tin khai báo thành công");
            } else {
              toastr.error("Xác nhận thông tin khai báo không thành công");
            }
            this.intTable();
          })
          .catch(() => {});
          break;
        default:
          break;
      }
    },

    async getApplicationData() {
      try {
        let res = await SurveyApi.findById(this.$router.currentRoute.params.id);
        if (res.result) {
          let resApplication = await ApplicationApi.findById(res.result.applicationId);
          if (resApplication.result) {
            this.createAble = true;
            this.applicationEnvironment = resApplication.result.environments;
            let hashCodeObject = {
              prefillData: "",
              privateKey: this.applicationEnvironment.find(x => x.envType == resApplication.result.defaultEnv).privateKey,
              callbackUrl: this.$router.resolve({ name: "Admin-Survey-detail", params: { id: this.$router.currentRoute.params.id } }).href,
              appCode: resApplication.result.code,
              env: resApplication.result.defaultEnv,
            }
            let responseHashCode = await mushroom.survey.generateHashCodeAsync(hashCodeObject);
            this.defaultRequestData = {
              env: hashCodeObject.env,
              appCode: hashCodeObject.appCode,
              hashCode: responseHashCode.result,
              privateKey: this.applicationEnvironment.find(x => x.envType == resApplication.result.defaultEnv).privateKey,
            }
          } else {
            this.createAble = false;
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },

  //run after render view
  created() {
    this.fn_hashUrl();
    if (this.isReach) {
      this.getApplicationData();
    }
  },
  mounted() {
    this.intTable();
  }
};
</script>
<style >
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
.el-checkbox-group.row {
  margin-left: 0px;
}
.el-table--small td,
.el-table--small th {
  padding: 6px 0px;
}
</style>
